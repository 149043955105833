import React, { useEffect } from "react";
import useStore from "../../store/store";
import { useNavigate, useLocation } from "react-router-dom";

function Auth() {
  const navigate = useNavigate();
  const { setIsLoggedIn } = useStore();
  const location = useLocation();

  const backendUrl = `${process.env.REACT_APP_URL}users/social/kakao`; // 백엔드 URL

  useEffect(() => {
    console.log("Auth component mounted.");

    const code = new URLSearchParams(location.search).get("code");
    console.log("Authorization code received:", code);

    if (code) {
      // 백엔드로 카카오 인증 코드를 POST 요청으로 전송
      axios
        .post(backendUrl, { code: code }) // 'code'라는 키로 인증 코드를 전송
        .then((response) => {
          console.log("response", response);
          console.log("Response received from backend:", response.data);
          setIsLoggedIn(true);
          // 인증이 성공하면 /dashboard로 리디렉션
          navigate("/dashboard");
        })
        .catch((error) => {
          console.error("Error during authentication process:", error);
          // 인증 실패 시 처리 로직 추가 가능
        });
    } else {
      console.error("Authorization code not found in URL.");
    }
  }, [location]);

  return (
    <div>
      <h2>로그인 중입니다...</h2>
    </div>
  );
}

export default Auth;
