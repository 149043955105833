import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { verifyCode } from "../../api/AuthApi";
import GF_Wordmark_Large from "../../assets/img/GF_Wordmark_Large.svg";
import GF_Logo from "../../assets/img/GF_Logo.svg";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const LeftPane = styled.div`
  background-color: #5f9137;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.div`
  text-align: center;
  color: white;
`;

const RightPane = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const FormContainer = styled.div`
  width: 36%;
  max-width: 400px;
`;

const LogoIcon = styled.div`
  text-align: center;
`;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 800;
  color: #5f9137;
  text-align: center;
  margin-bottom: 0.3rem;
`;

const Subtitle = styled.div`
  font-size: 0.75rem;
  color: #2e3829;
  text-align: center;
  margin-bottom: 5rem;
`;

const CodeInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const CodeInput = styled.input`
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  border: 1px solid #efefef;
  border-radius: 8px;
  border-color: ${(props) => (props.hasvalue ? "#5F9137" : "#EFEFEF")};
  color: ${(props) => (props.hasvalue ? "#5F9137" : "#EFEFEF")};
  outline: none;
  transition: background-color 0.3s ease-in-out;

  &:focus {
    border-color: #5f9137;
    caret-color: transparent;
  }

  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  background-color: ${(props) => (props.disabled ? "#F5F5F5" : "white")};
`;

const TextContainer = styled.div`
  display: flex;
  padding-bottom: 5rem;
`;

const ResendLink = styled.a`
  color: #4d4d4d;
  cursor: pointer;
  font-size: 0.9rem;
  text-decoration: underline;
  display: block;
  text-align: center;
  margin-bottom: 20px;
`;

const ContinueButton = styled.button`
  width: 100%;
  padding: 0.4rem 0.6rem;
  background-color: #5f9137;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.875rem;
  cursor: pointer;
  margin-bottom: 20px;
`;

const ContactLink = styled.a`
  color: #5f9137;
  font-size: 0.875rem;
  text-align: center;
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const EmailVerification = () => {
  const [code, setCode] = useState(["", "", "", ""]);
  const inputsRef = useRef([]);
  const navigate = useNavigate();

  // 컴포넌트 마운트될 때 첫번째에 포커스 두기
  useEffect(() => {
    inputsRef.current[0].focus();
  }, []);

  const handleChange = (value, index) => {
    // 숫자만
    if (isNaN(value) || value.length > 1) return;

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // 숫자 입력되면 다음으로 넘어가기
    if (value !== "" && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newCode = [...code];
      newCode[index] = "";
      setCode(newCode);

      if (index > 0) {
        inputsRef.current[index - 1].focus();
      }
    }
  };

  const handleSubmit = async () => {
    const verificationCode = code.join(""); // 4자리 코드 합치기

    await verifyCode(verificationCode)
      .then(() => {
        navigate("/dashboard");
      })
      .catch((err) => console.error("코드 검증 실패", err));
  };

  return (
    <Container>
      <LeftPane>
        <Logo>
          <img src={GF_Wordmark_Large} alt="Goat Farm Wordmark" />
        </Logo>
      </LeftPane>
      <RightPane>
        <FormContainer>
          <LogoIcon>
            <img src={GF_Logo} alt="Goat Farm Logo" />
          </LogoIcon>
          <Title>Verify your email</Title>
          <Subtitle>
            We sent a code to your email. Enter your verification code.
          </Subtitle>

          <CodeInputContainer>
            {code.map((digit, index) => (
              <CodeInput
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                hasvalue={digit !== "" ? "Y" : undefined}
                ref={(el) => (inputsRef.current[index] = el)}
                autoFocus={index === 0} // Autofocus the first input
              />
            ))}
          </CodeInputContainer>
          <TextContainer>
            <div>Didn't get a code? </div>
            <ResendLink href="#">Click here to resend</ResendLink>
          </TextContainer>

          <ContinueButton onClick={handleSubmit}>Continue</ContinueButton>

          <ContactLink href="/contact">Contact</ContactLink>
        </FormContainer>
      </RightPane>
    </Container>
  );
};

export default EmailVerification;
