import React from "react";
import {
  BrowserRouter as Router, // 서버에 올릴 때는 BrowserRouter 사용 후 build/index.html에서 js파일 주소 절대경로로 변경
  // HashRouter as Router,  // 로컬에서 실행할 때는 HashRouter 사용 (카카오 로그인 안됨...)
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./Home.js";
import Dashboard from "./Dashboard/index.js";
import CCTV from "./CCTV/index.js";
import Data from "./Data/index.js";
import Notification from "./Notification/index.js";
import Monitoring from "./Monitoring/index.js";
import JournalDetail from "./Monitoring/JournalDetail.js";
import JournalForm from "./Monitoring/JournalForm.js";
import JournalEdit from "./Monitoring/JournalEdit.js";
import Settings from "./Settings/index.js";
import Login from "./Login/index.js";
import Auth from "./Login/Auth.js";
import Verify from "./Login/Verify.js";
import useStore from "../store/store.js";

function AppRouter() {
  const { isLoggedIn } = useStore();
  return (
    <>
      <Router>
        {isLoggedIn ? (
          <Routes>
            <Route path="/" element={<Home />}>
              <Route
                path=""
                exact
                element={<Navigate replace to="/dashboard" />}
              />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/cctv" element={<CCTV />} />
              <Route path="/data" element={<Data />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/monitoring" element={<Monitoring />} />
              <Route
                path="/monitoring/journals/:journal_seq"
                element={<JournalDetail />}
              />
              <Route
                path="/monitoring/journals/create"
                element={<JournalForm />}
              />
              <Route
                path="/monitoring/journals/:journal_seq/edit"
                element={<JournalEdit />}
              />
              <Route path="/settings" element={<Settings />} />
              <Route path="/verify" element={<Verify />} />
              <Route path="/login" element={<Login />} />
            </Route>
          </Routes>
        ) : (
          <Routes>
            {/* 기본 경로에 Login/SocialKakao 컴포넌트를 렌더링합니다. */}
            <Route path="/" element={<Login />} />
            {/* 카카오 로그인 후 리디렉션되는 경로에 Auth 컴포넌트를 렌더링합니다. */}
            <Route path="/auth" element={<Auth />} />
            <Route path="/verify" element={<Verify />} />
          </Routes>
        )}
      </Router>
    </>
  );
}

export default AppRouter;
