import React, { useEffect, useState } from "react";
import AppRouter from "../src/pages/Router";
import Modal from "./components/Modal";
import Card from "./components/Card";
import { SocketProvider } from "./socket/Socket";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const checkConnection = async () => {
    if (!navigator.onLine) {
      console.log("Navigator is offline");
      setIsOnline(false);
      return;
    }

    try {
      const response = await fetch("https://www.google.com", {
        mode: "no-cors",
      });
      setIsOnline(true);
    } catch (error) {
      console.log("Fetch error:", error);
      setIsOnline(false);
    }
  };

  // production 에서 콘솔 제거
  // if (process.env.NODE_ENV === "production") {
  //   // production에서만 사용할 수 없도록
  //   console = window.console || {};
  //   console.log = function no_console() {}; // console log 막기
  //   console.warn = function no_console() {}; // console warning 막기
  //   console.error = function () {}; // console error 막기
  // }

  useEffect(() => {
    checkConnection();

    window.addEventListener("online", checkConnection);
    window.addEventListener("offline", checkConnection);

    return () => {
      window.removeEventListener("online", checkConnection);
      window.removeEventListener("offline", checkConnection);
    };
  }, []);

  return (
    <>
      {!isOnline ? (
        <Modal
          show={!isOnline}
          content={
            <Card
              title="인터넷 연결 유실"
              close={() => {}}
              text="네트워크 연결을 확인해주세요."
            />
          }
          reject={() => {}}
          style={{ height: "6.5rem", margin: "40vh auto" }}
        />
      ) : (
        <SocketProvider>
          <AppRouter />
        </SocketProvider>
      )}
    </>
  );
}

export default App;
