import { tnsInstance } from "./Axios";
import { getFarmConfig } from "../utils/getFarmConfig";

export const getCCTV = async (farm_id) => {
  const farmConfig = getFarmConfig(farm_id);

  if (!farmConfig) {
    throw new Error(`No configuration found for farm_id: ${farm_id}`);
  }

  const response = await tnsInstance.get("tns/ict/getfarminfo", {
    params: {
      usr_id: farmConfig.id,
    },
  });
  return response.data.data[0].camera;
};
