import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import KakaoLogin from "react-kakao-login";
import useStore from "../../store/store";
import Login_Kakao from "../../assets/img/Login_Kakao.svg";
import { kakaoLogin } from "../../api/AuthApi";

const SocialKakao = () => {
  const navigate = useNavigate();
  const { setIsLoggedIn } = useStore();

  const kakaoClientIdJavascript =
    process.env.REACT_APP_KAKAO_CLIENT_ID_JAVASCRIPT; // 제공된 JavaScript 키

  const handleButtonClick = () => {
    console.log("로그인 버튼 눌림");
  };

  const kakaoOnSuccess = async (data) => {
    const accessToken = data.response.access_token; // 엑세스 토큰을 받아옴
    await kakaoLogin(accessToken)
      .then(() => {
        localStorage.setItem("access_token", JSON.stringify(accessToken));
        setIsLoggedIn(true);
        navigate("/dashboard");
      })
      .catch((err) =>
        console.error("Error during token exchange with backend:", err)
      );
  };

  const kakaoOnFailure = (error) => {
    console.error("Kakao login failed:", error);
  };

  return (
    <>
      <KakaoLogin
        token={kakaoClientIdJavascript}
        onSuccess={kakaoOnSuccess}
        onFail={kakaoOnFailure}
        useLoginForm // 팝업 대신 리디렉션을 이용해 로그인합니다.
        onClick={handleButtonClick} // 로그인 버튼 클릭 시 호출되는 함수
        style={{
          width: "100%",
          padding: "0.4rem 0.6rem",
          backgroundColor: "#5f9137",
          color: "white",
          border: "none",
          borderRadius: "5px",
          fontSize: "0.875rem",
          cursor: "pointer",
          backgroundColor: "#fee500",
          color: "#4d4d4d",
          padding: "0 0.6rem",
          height: "1.875rem",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={Login_Kakao} alt="Kakao" />
        Log in with Kakao
      </KakaoLogin>
    </>
  );
};

export default SocialKakao;
