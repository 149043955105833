import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useStore from "../store/store";
import Sidebar from "../components/layout/SideBar";
import Navbar from "../components/layout/Navbar";
import { getFarm, getFarmInitial } from "../api/AuthApi";

const OutletWrapper = styled.div`
  margin: 3.9rem 0 0 4.2rem;
`;

const Home = () => {
  const navigate = useNavigate();
  const {
    api_key,
    user_id,
    farm_id,
    setFarmId,
    setPlCode,
    setPlModel,
    setDevice,
    setSocketUrl,
  } = useStore();

  useEffect(() => {
    getFarm(api_key, user_id)
      .then((res) => {
        setFarmId(res.farm_id); // demo
        setPlCode(res.pl_code);
        setPlModel(res.pl_model);
        setDevice(res.device);
      })
      .catch((err) => console.log(err));
    // 농장 선택 안했으면 선택하는 페이지로 보내기
    if (farm_id === undefined || farm_id === null) {
      navigate("/settings");
    } else {
      getFarmInitial(farm_id)
        .then((res) => {
          setSocketUrl(`ws://${res.remoteip}:${res.sp}`);
        })
        .then(() => navigate("/dashboard"));
    }
  }, [farm_id]);

  return (
    <>
      <Sidebar />
      <Navbar />
      <OutletWrapper>
        <Outlet />
      </OutletWrapper>
    </>
  );
};

export default Home;
